import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CartContext } from '../context/CartContext';

const ViewProduct = () => {
    const { productID } = useParams();
    const { addToCart, removeFromCart, isProductInCart } = useContext(CartContext);
    const [productInfo, setProductInfo] = useState(null);
    const domain = window.location.href.includes('localhost') ? "http://localhost:5000" : "https://blood-sugar-backend.onrender.com";
    const navigate = useNavigate()

    const [selectedImg, setSelectedImg] = useState(null)

    const getProduct = async () => {
        try {
            const response = await axios.get(domain + `/api/products/${productID}`);
            if (response.data) {
                setProductInfo(response.data);
                setSelectedImg(productInfo.imageUrls[0])
            }
        } catch (error) {
            console.error('Error fetching product:', error);
        }
    };

    console.log(productInfo);
    

    useEffect(() => {
        getProduct();
    }, [productID]);

    const handleCartAction = () => {
        if (productInfo) {
            if (isProductInCart(productInfo._id)) {
                removeFromCart(productInfo);
            } else {
                addToCart(productInfo);
            }
        }
    };

    if (!productInfo) return <div>Loading...</div>;

    const goToCheckout = async () => {
        if (productInfo) {
            if (!isProductInCart(productInfo._id)) {
                await addToCart(productInfo); // Ensure addToCart completes
            }
            navigate('/cart'); // Navigate after ensuring the cart is updated
        }
    };

    return (
        <div className='mx-10 my-4'>
            {/* <div className='text-xl my-4'>ViewProduct: {productInfo.productName}</div> */}
            <div className='grid grid-cols-5 gap-4 my-8'>
                <div className='col-span-5 lg:col-span-3 gap-4 px-2'>
                    <img className='md:w-[80%] m-auto border border-2 rounded-md' src={selectedImg ||  productInfo.imageUrls[0]} alt={productInfo.productName} />
                    <div className='flex justify-center gap-1 m-2'>
                        {productInfo.imageUrls.map((imgUrl) => (
                            <img className='h-[100px] m-2 border border-2 rounded-md hover:border-red-500' onClick={() => setSelectedImg(imgUrl)} src={imgUrl} alt={productInfo.productName} />
                        ))} 
                    </div>
                </div>
                <div className='col-span-5 lg:col-span-2 px-4'>
                    <p className='text-3xl my-4 font-semibold'>{productInfo.productName}</p>
                    <p className='text-slate-600 my-4'>{productInfo.productDescription}</p>
                    <div className='flex items-end gap-2'>
                        <p className='my-4 text-2xl text-green-600'>R {(productInfo.price).toFixed(2)}</p>
                        <p className='my-4 text-slate-500 line-through'>R {(productInfo.price*2.21667).toFixed(2)}</p>
                    </div>
                    <div className='flex'>
                       <button 
                        className='my-4 h-[50px] w-[100%] my-3 text-white rounded-full bg-gradient-to-r from-purple-500 to-pink-500'
                        onClick={goToCheckout}
                        >
                            {/* {isProductInCart(productInfo._id) ? 'Remove from Cart' : 'Add to Cart'} */}
                            Buy Now
                        </button>
                        
                    </div>
                    
                    <div>
                        <p className='my-4 font-semibold text-lg'>Delivery</p>
                        <div>
                            <p className='my-4'>Instant Download</p>
                            <p className='my-4 font-thin'>
                                All files will be made available for download once payment has been made. 
                            </p>
                            <p className='my-4 font-thin'>
                                The download will be availble in the Orders section on the Profile page. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewProduct;
